import React from 'react';
import Gallery from './../Gallery/Gallery';

const Projects = () => {
  return (
    <div>
      <h1>Projects</h1>
      <Gallery></Gallery>
    </div>
  );
}

export default Projects;